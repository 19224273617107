import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaArrowLeft, FaExclamationTriangle, FaPaperPlane } from 'react-icons/fa';
import SEO from './SEO';
import './ReportIssue.css';

const ReportIssue = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState('');
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };
  
  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Basic validation
    if (!formData.name || !formData.email || !formData.subject || !formData.message) {
      setError('Please fill in all fields');
      return;
    }
    
    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      setError('Please enter a valid email address');
      return;
    }
    
    // Create mailto link with form data
    const mailtoLink = `mailto:agristaff@doa.gov.lk?subject=${encodeURIComponent(formData.subject)}&body=${encodeURIComponent(
      `Name: ${formData.name}\nEmail: ${formData.email}\n\n${formData.message}`
    )}`;
    
    // Open mailto link
    window.location.href = mailtoLink;
    
    // Reset form and show success message
    setFormData({
      name: '',
      email: '',
      subject: '',
      message: ''
    });
    setError('');
    setSubmitted(true);
  };
  
  return (
    <div className="report-page">
      <SEO 
        title="Report an Issue - AgriStaff Directory | Department of Agriculture Sri Lanka"
        description="Submit a report about incorrect information or technical issues with the AgriStaff Directory. Help us improve our service by reporting problems."
        pathname="/report"
      />
      <div className="report-header">
        <div className="header-overlay">
          <div className="header-content">
            <FaExclamationTriangle className="report-icon" />
            <h1>Report an Issue</h1>
          </div>
        </div>
      </div>
      
      <div className="report-container">
        <div className="back-nav">
          <Link to="/" className="back-button">
            <FaArrowLeft className="back-icon" /> Back to Directory
          </Link>
        </div>
        
        <div className="report-content">
          <p className="intro-text">
            Please use this form to report incorrect information or any issues you encounter with the AgriStaff directory.
          </p>
          
          {submitted && (
            <div className="success-message">
              <h3>Thank you for your report!</h3>
              <p>Your message has been sent to our team.</p>
              <button 
                className="new-report-button"
                onClick={() => setSubmitted(false)}
              >
                Submit another report
              </button>
            </div>
          )}
          
          {!submitted && (
            <form className="report-form" onSubmit={handleSubmit}>
              {error && <div className="error-message">{error}</div>}
              
              <div className="form-group">
                <label htmlFor="name">Your Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Enter your full name"
                />
              </div>
              
              <div className="form-group">
                <label htmlFor="email">Your Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Enter your email address"
                />
              </div>
              
              <div className="form-group">
                <label htmlFor="subject">Subject</label>
                <input
                  type="text"
                  id="subject"
                  name="subject"
                  value={formData.subject}
                  onChange={handleChange}
                  placeholder="Enter subject"
                />
              </div>
              
              <div className="form-group">
                <label htmlFor="message">Message</label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  placeholder="Describe the issue in detail"
                  rows="6"
                ></textarea>
              </div>
              
              <button type="submit" className="submit-button">
                <FaPaperPlane className="submit-icon" /> Send Report
              </button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReportIssue; 