import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Update footer contact stats when contact data is available
const updateContactStats = () => {
  const contactStats = document.getElementById('contact-stats');
  const footerContactStats = document.getElementById('footer-contact-stats');
  
  if (contactStats && footerContactStats) {
    const totalContacts = contactStats.getAttribute('data-total');
    const filteredContacts = contactStats.getAttribute('data-filtered');
    
    if (totalContacts && filteredContacts) {
      footerContactStats.textContent = `Total Contacts: ${totalContacts} | Filtered: ${filteredContacts}`;
    }
  }
  
  // Check again after a short delay
  setTimeout(updateContactStats, 1000);
};

// Start checking for contact stats
updateContactStats();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
