import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaArrowLeft, FaShieldAlt, FaLock, FaUserShield, FaHandshake, FaBullhorn, FaPen, FaPhoneAlt } from 'react-icons/fa';
import SEO from './SEO';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
  const [activeSection, setActiveSection] = useState(null);

  const toggleSection = (sectionId) => {
    if (activeSection === sectionId) {
      setActiveSection(null);
    } else {
      setActiveSection(sectionId);
      // Scroll to section
      const element = document.getElementById(`section-${sectionId}`);
      if (element) {
        const headerOffset = 100;
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
        
        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });
      }
    }
  };
  
  return (
    <div className="privacy-policy-page">
      <SEO 
        title="Privacy Policy - AgriStaff Directory | Department of Agriculture Sri Lanka"
        description="Our privacy policy explains how we collect, use, and protect contact information in the AgriStaff Directory. Learn about data security, third-party sharing policies, and user responsibilities."
        pathname="/privacy-policy"
      />
      <div className="privacy-header">
        <div className="header-overlay">
          <div className="header-content">
            <FaShieldAlt className="privacy-shield-icon" />
            <h1>Privacy Policy</h1>
            <p className="effective-date">Effective Date: January 1, 2025</p>
          </div>
        </div>
      </div>
      
      <div className="privacy-container">
        <div className="back-nav">
          <Link to="/" className="back-button">
            <FaArrowLeft className="back-icon" /> Back to Directory
          </Link>
        </div>
        
        <div className="policy-layout">
          <nav className="policy-nav">
            <ul>
              <li className={activeSection === 1 ? 'active' : ''}>
                <button onClick={() => toggleSection(1)}>
                  <FaLock className="nav-icon" /> Information Collection
                </button>
              </li>
              <li className={activeSection === 2 ? 'active' : ''}>
                <button onClick={() => toggleSection(2)}>
                  <FaHandshake className="nav-icon" /> Purpose of Information
                </button>
              </li>
              <li className={activeSection === 3 ? 'active' : ''}>
                <button onClick={() => toggleSection(3)}>
                  <FaShieldAlt className="nav-icon" /> Data Security
                </button>
              </li>
              <li className={activeSection === 4 ? 'active' : ''}>
                <button onClick={() => toggleSection(4)}>
                  <FaUserShield className="nav-icon" /> Third-Party Sharing
                </button>
              </li>
              <li className={activeSection === 5 ? 'active' : ''}>
                <button onClick={() => toggleSection(5)}>
                  <FaUserShield className="nav-icon" /> User Responsibilities
                </button>
              </li>
              <li className={activeSection === 6 ? 'active' : ''}>
                <button onClick={() => toggleSection(6)}>
                  <FaBullhorn className="nav-icon" /> Policy Updates
                </button>
              </li>
              <li className={activeSection === 7 ? 'active' : ''}>
                <button onClick={() => toggleSection(7)}>
                  <FaPhoneAlt className="nav-icon" /> Contact Us
                </button>
              </li>
            </ul>
          </nav>
          
          <div className="privacy-content">
            <p className="intro-text">
              Welcome to the Agri Staff Contact Directory web and mobile application. This Privacy Policy outlines how we collect, use, and protect the contact information displayed within the application.
            </p>
            
            <div id="section-1" className="policy-section">
              <div className="section-header">
                <FaLock className="section-icon" />
                <h2>1. Information Collection and Usage</h2>
              </div>
              <div className="section-content">
                <p>
                  The Agri Staff Contact Directory displays contact details of agricultural staff and related personnel. All contact information included in the directory is displayed with the explicit permission of the respective individuals. The collected data is solely intended for official and professional use within the agricultural sector.
                </p>
              </div>
            </div>
            
            <div id="section-2" className="policy-section">
              <div className="section-header">
                <FaHandshake className="section-icon" />
                <h2>2. Purpose of Contact Information</h2>
              </div>
              <div className="section-content">
                <p>
                  The contact details provided in this application are intended strictly for official matters related to agriculture and related services. Users are prohibited from using this information for:
                </p>
                <ul>
                  <li>Personal or commercial solicitation</li>
                  <li>Unsolicited marketing or advertising</li>
                  <li>Any unauthorized purpose beyond professional communication</li>
                </ul>
              </div>
            </div>
            
            <div id="section-3" className="policy-section">
              <div className="section-header">
                <FaShieldAlt className="section-icon" />
                <h2>3. Data Security</h2>
              </div>
              <div className="section-content">
                <p>
                  We take appropriate security measures to ensure that the contact information is protected from unauthorized access, alteration, or misuse. However, users are responsible for maintaining the confidentiality of the information accessed through the directory.
                </p>
              </div>
            </div>
            
            <div id="section-4" className="policy-section">
              <div className="section-header">
                <FaUserShield className="section-icon" />
                <h2>4. Third-Party Sharing</h2>
              </div>
              <div className="section-content">
                <p>
                  We do not sell, trade, or share any contact information with third parties without the explicit consent of the individual, except when required by law or regulatory authorities.
                </p>
              </div>
            </div>
            
            <div id="section-5" className="policy-section">
              <div className="section-header">
                <FaUserShield className="section-icon" />
                <h2>5. User Responsibilities</h2>
              </div>
              <div className="section-content">
                <p>
                  By using the Agri Staff Contact Directory, you agree to:
                </p>
                <ul>
                  <li>Use the contact details only for their intended purpose.</li>
                  <li>Refrain from distributing or sharing the information with unauthorized individuals or entities.</li>
                  <li>Report any misuse or unauthorized access to the relevant authorities.</li>
                </ul>
              </div>
            </div>
            
            <div id="section-6" className="policy-section">
              <div className="section-header">
                <FaBullhorn className="section-icon" />
                <h2>6. Updates to This Policy</h2>
              </div>
              <div className="section-content">
                <p>
                  We may update this Privacy Policy from time to time to reflect changes in our practices. Users will be notified of any significant changes through the application or website.
                </p>
              </div>
            </div>
            
            <div id="section-7" className="policy-section">
              <div className="section-header">
                <FaPhoneAlt className="section-icon" />
                <h2>7. Contact Us</h2>
              </div>
              <div className="section-content">
                <p>
                  If you have any questions or concerns regarding this Privacy Policy, please contact us at:
                </p>
                <div className="contact-box">
                  <address>
                    <strong>Television & Farm Broadcasting Service</strong><br />
                    National Agriculture Information and Communication Center<br />
                    Peradeniya, 20400<br /><br />
                    <a href="tel:+94812388388">+94 81 2 388 388</a><br />
                    <a href="mailto:agristaff@doa.gov.lk">agristaff@doa.gov.lk</a>
                  </address>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <footer className="privacy-footer">
          <div className="footer-content">
            <div className="copyright-text">
              © 2016 - {new Date().getFullYear()} Department of Agriculture, Sri Lanka. All rights reserved.
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default PrivacyPolicy; 