import React from 'react';
import { Link } from 'react-router-dom';
import { FaArrowLeft, FaPhoneAlt, FaEnvelope, FaMapMarkerAlt, FaClock } from 'react-icons/fa';
import SEO from './SEO';
import './ContactUs.css';

const ContactUs = () => {
  return (
    <div className="contact-page">
      <SEO 
        title="Contact Us - AgriStaff Directory | Department of Agriculture Sri Lanka"
        description="Contact the Department of Agriculture AgriStaff Directory team for inquiries, support, or to report information. Find our phone number, email, address and office hours."
        pathname="/contact-us"
      />
      <div className="contact-header">
        <div className="header-overlay">
          <div className="header-content">
            <div className="icon-container">
              <FaPhoneAlt className="contact-icon" />
            </div>
            <h1>Contact Us</h1>
          </div>
        </div>
      </div>
      
      <div className="contact-container">
        <div className="back-nav">
          <Link to="/" className="back-button">
            <FaArrowLeft className="back-icon" /> Back to Directory
          </Link>
        </div>
        
        <div className="contact-content">
          <p className="intro-text">
            Please contact us for any inquiries or to report incorrect information in the directory.
          </p>
          
          <div className="contact-info-section">
            <div className="contact-info-item">
              <div className="info-icon-container">
                <FaMapMarkerAlt className="info-icon" />
              </div>
              <div className="info-content">
                <h3>Address</h3>
                <p>Television & Farm Broadcasting Service,</p>
                <p>National Agriculture Information and Communication Center,</p>
                <p>Gannoruwa, Peradeniya.</p>
              </div>
            </div>
            
            <div className="contact-info-item">
              <div className="info-icon-container">
                <FaPhoneAlt className="info-icon" />
              </div>
              <div className="info-content">
                <h3>Phone Number</h3>
                <p>+94 81 2 388 388</p>
              </div>
            </div>
            
            <div className="contact-info-item">
              <div className="info-icon-container">
                <FaClock className="info-icon" />
              </div>
              <div className="info-content">
                <h3>Office Hours</h3>
                <p>Monday to Friday, 9 AM - 4 PM</p>
              </div>
            </div>
            
            <div className="contact-info-item">
              <div className="info-icon-container">
                <FaEnvelope className="info-icon" />
              </div>
              <div className="info-content">
                <h3>Email Address</h3>
                <p><a href="mailto:agristaff@doa.gov.lk">agristaff@doa.gov.lk</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs; 