import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import './App.css';
import ContactsDirectory from './components/ContactsDirectory';
import ContactDetail from './components/ContactDetail';
import PrivacyPolicy from './components/PrivacyPolicy';
import MobileAppPrivacyPolicy from './components/MobileAppPrivacyPolicy';
import AboutUs from './components/AboutUs';
import ReportIssue from './components/ReportIssue';
import ContactUs from './components/ContactUs';
import SEO from './components/SEO';
import { FaShieldAlt, FaMobileAlt, FaInfoCircle, FaExclamationTriangle, FaPhoneAlt, FaAddressBook, FaGooglePlay, FaApple, FaTimes, FaArrowDown, FaBars } from 'react-icons/fa';

function App() {
  const [showPopup, setShowPopup] = useState(false);
  const [deviceType, setDeviceType] = useState('unknown');
  const [menuOpen, setMenuOpen] = useState(false);
  
  useEffect(() => {
    // Detect device type on component mount
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    
    if (/android/i.test(userAgent)) {
      setDeviceType('android');
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      setDeviceType('ios');
    }
    
    // Close mobile menu on window resize to desktop size
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setMenuOpen(false);
      }
    };
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  const handleAppStoreClick = (e) => {
    e.preventDefault();
    setShowPopup(true);
    
    // Auto-close popup after 3 seconds
    setTimeout(() => {
      setShowPopup(false);
    }, 3000);
  };
  
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  
  const closeMenu = () => {
    setMenuOpen(false);
  };
  
  return (
    <HelmetProvider>
      <div className="App">
        <SEO />
        <Router>
          <header className="App-header">
            <div className="header-container">
              <Link to="/" className="header-logo" onClick={closeMenu}>
                <img src="/agristaff_logo.png" alt="AgriStaff Logo" className="site-logo" />
                <h1>AgriStaff Directory</h1>
              </Link>
              
              <button className="mobile-menu-toggle" onClick={toggleMenu} aria-label="Toggle navigation menu">
                <FaBars />
              </button>
              
              <nav className={`header-nav ${menuOpen ? 'menu-open' : ''}`}>
                <div className="menu-title">
                  <img src="/agristaff_logo.png" alt="AgriStaff Logo" className="menu-logo" />
                  <h2>Menu</h2>
                </div>
                <ul className="nav-menu">
                  <li className="nav-item">
                    <Link to="/" className="nav-link" onClick={closeMenu}>
                      <FaAddressBook className="nav-icon" /> Contacts
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/about" className="nav-link" onClick={closeMenu}>
                      <FaInfoCircle className="nav-icon" /> About Us
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/contact-us" className="nav-link" onClick={closeMenu}>
                      <FaPhoneAlt className="nav-icon" /> Contact Us
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/report" className="nav-link" onClick={closeMenu}>
                      <FaExclamationTriangle className="nav-icon" /> Report Issue
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/privacy-policy" className="nav-link" onClick={closeMenu}>
                      <FaShieldAlt className="nav-icon" /> Web Privacy
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/mobile-privacy-policy" className="nav-link" onClick={closeMenu}>
                      <FaMobileAlt className="nav-icon" /> Mobile Privacy
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
            
            {deviceType === 'android' ? (
              <div className="header-app-links device-specific android-highlight">
                <div className="header-app-label">
                  <FaArrowDown className="indicator-arrow" /> Get on Android <FaArrowDown className="indicator-arrow" />
                </div>
                <div className="header-store-buttons">
                  <a href="https://play.google.com/store/apps/details?id=com.tfbsk.agristafflk" target="_blank" rel="noopener noreferrer" className="header-store-link highlight-store-link">
                    <FaGooglePlay className="header-store-icon" /> Download for your device
                  </a>
                  <a href="#" onClick={handleAppStoreClick} className="header-store-link low-emphasis">
                    <FaApple className="header-store-icon" /> App Store
                  </a>
                </div>
              </div>
            ) : deviceType === 'ios' ? (
              <div className="header-app-links device-specific ios-highlight">
                <div className="header-app-label">iOS app coming soon!</div>
                <div className="header-store-buttons">
                  <a href="https://play.google.com/store/apps/details?id=com.tfbsk.agristafflk" target="_blank" rel="noopener noreferrer" className="header-store-link low-emphasis">
                    <FaGooglePlay className="header-store-icon" /> Google Play
                  </a>
                  <a href="#" onClick={handleAppStoreClick} className="header-store-link">
                    <FaApple className="header-store-icon" /> Available soon
                  </a>
                </div>
              </div>
            ) : (
              <div className="header-app-links">
                <div className="header-app-label">Get the mobile app:</div>
                <div className="header-store-buttons">
                  <a href="https://play.google.com/store/apps/details?id=com.tfbsk.agristafflk" target="_blank" rel="noopener noreferrer" className="header-store-link">
                    <FaGooglePlay className="header-store-icon" /> Google Play
                  </a>
                  <a href="#" onClick={handleAppStoreClick} className="header-store-link">
                    <FaApple className="header-store-icon" /> App Store
                  </a>
                </div>
              </div>
            )}
          </header>
          
          <main className="app-main-content">
            <Routes>
              <Route path="/" element={<ContactsDirectory />} />
              <Route path="/contact/:id" element={<ContactDetail />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/mobile-privacy-policy" element={<MobileAppPrivacyPolicy />} />
              <Route path="/about" element={<AboutUs />} />
              <Route path="/report" element={<ReportIssue />} />
              <Route path="/contact-us" element={<ContactUs />} />
            </Routes>
          </main>
          
          <footer className="app-footer">
            <div className="footer-content">
              <div className="mobile-app-links">
                <a href="https://play.google.com/store/apps/details?id=com.tfbsk.agristafflk" target="_blank" rel="noopener noreferrer" className="app-store-link">
                  <FaGooglePlay className="app-store-icon" /> Get it on Google Play
                </a>
                <a href="#" onClick={handleAppStoreClick} className="app-store-link">
                  <FaApple className="app-store-icon" /> Download on the App Store
                </a>
              </div>
              <div className="copyright-text">
                © 2016 - {new Date().getFullYear()} Department of Agriculture, Sri Lanka. All rights reserved.
              </div>
              <div className="attribution">
                <div>Developed by Television and Farmers Broadcasting Service</div>
                <div>Data provided by Graphics Communication and Training Unit</div>
              </div>
              <div className="contact-stats" id="footer-contact-stats">
                {/* Will be populated via JavaScript */}
              </div>
            </div>
          </footer>
          
          {showPopup && (
            <div className="app-popup">
              <div className="popup-content">
                <button className="close-popup" onClick={() => setShowPopup(false)}>
                  <FaTimes />
                </button>
                <FaApple className="popup-icon" />
                <p>iOS App will be available soon!</p>
              </div>
            </div>
          )}
        </Router>
      </div>
    </HelmetProvider>
  );
}

export default App;
