import React from 'react';
import { Helmet } from 'react-helmet-async';

const SEO = ({ 
  title = 'AgriStaff Directory - Department of Agriculture, Sri Lanka',
  description = 'Official staff directory for the Department of Agriculture in Sri Lanka. Find contact information for agricultural officers, researchers, and extension workers.',
  pathname = '',
  image = '/agristaff_logo.png',
  children = null
}) => {
  
  const siteUrl = 'https://agristaff.gov.lk'; // Replace with your actual domain
  const canonical = pathname ? `${siteUrl}${pathname}` : siteUrl;
  
  // JSON-LD structured data for organization
  const organizationSchema = {
    '@context': 'https://schema.org',
    '@type': 'GovernmentOrganization',
    '@id': siteUrl,
    name: 'Department of Agriculture, Sri Lanka',
    url: siteUrl,
    logo: `${siteUrl}/agristaff_logo.png`,
    sameAs: [
      'https://www.doa.gov.lk',
      'https://www.facebook.com/srilankaagridept'
    ],
    address: {
      '@type': 'PostalAddress',
      streetAddress: 'National Agriculture Information and Communication Center',
      addressLocality: 'Peradeniya',
      postalCode: '20400',
      addressCountry: 'LK'
    },
    contactPoint: {
      '@type': 'ContactPoint',
      telephone: '+94-81-2388388',
      contactType: 'customer service',
      email: 'agristaff@doa.gov.lk'
    }
  };
  
  // JSON-LD structured data for website
  const websiteSchema = {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    '@id': `${siteUrl}/#website`,
    url: siteUrl,
    name: 'AgriStaff Directory',
    description: 'Official staff directory for the Department of Agriculture, Sri Lanka',
    publisher: {
      '@id': siteUrl
    }
  };
  
  // Generate page-specific breadcrumb structured data
  const breadcrumbSchema = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: 1,
        name: 'Home',
        item: siteUrl
      }
    ]
  };
  
  // Add second breadcrumb item if not on homepage
  if (pathname && pathname !== '/') {
    const pageName = title.split(' - ')[0];
    breadcrumbSchema.itemListElement.push({
      '@type': 'ListItem',
      position: 2,
      name: pageName,
      item: canonical
    });
  }
  
  return (
    <Helmet>
      {/* Basic meta tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={canonical} />
      
      {/* OpenGraph/Facebook meta tags */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={canonical} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={`${siteUrl}${image}`} />
      
      {/* Twitter meta tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:url" content={canonical} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={`${siteUrl}${image}`} />
      
      {/* Optional - specify keywords */}
      <meta name="keywords" content="agriculture, sri lanka, department of agriculture, staff directory, agricultural officers, agricultural research, extension services" />
      
      {/* Language/locale */}
      <meta property="og:locale" content="en_US" />
      <html lang="en" />
      
      {/* Structured Data / JSON-LD */}
      <script type="application/ld+json">
        {JSON.stringify(organizationSchema)}
      </script>
      <script type="application/ld+json">
        {JSON.stringify(websiteSchema)}
      </script>
      <script type="application/ld+json">
        {JSON.stringify(breadcrumbSchema)}
      </script>
      
      {children}
    </Helmet>
  );
};

export default SEO; 