import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './ContactsDirectory.css';
import ContactCard from './ContactCard';
import { FaSearch, FaChevronLeft, FaChevronRight, FaUsers, FaSpinner, FaBuilding, FaMapMarkerAlt, FaSitemap, FaTimes, FaShieldAlt, FaChevronDown, FaChevronUp } from 'react-icons/fa';

const CACHE_KEY = 'agristaff_contacts_data';
const CACHE_TIMESTAMP_KEY = 'agristaff_contacts_timestamp';
const CACHE_VALIDITY_PERIOD = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

const ContactsDirectory = () => {
  const [allContacts, setAllContacts] = useState([]);
  const [displayedContacts, setDisplayedContacts] = useState([]);
  const [filteredContacts, setFilteredContacts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [totalToFetch, setTotalToFetch] = useState(0);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [instituteSearchTerm, setInstituteSearchTerm] = useState('');
  const [stationSearchTerm, setStationSearchTerm] = useState('');
  const [unitSearchTerm, setUnitSearchTerm] = useState('');
  const [usingCache, setUsingCache] = useState(false);
  
  const CONTACTS_PER_PAGE = 12; // Display 12 contacts per page for client-side pagination

  useEffect(() => {
    const fetchAllContacts = async () => {
      try {
        setLoading(true);
        
        // Check if we have cached data first
        const cachedTimestamp = localStorage.getItem(CACHE_TIMESTAMP_KEY);
        const cachedData = localStorage.getItem(CACHE_KEY);
        const currentTime = new Date().getTime();
        
        // If we have valid cached data that's not expired
        if (cachedData && cachedTimestamp && 
            (currentTime - parseInt(cachedTimestamp)) < CACHE_VALIDITY_PERIOD) {
          
          // Use cached data
          setUsingCache(true);
          setLoadingProgress(100); // Simulate immediate loading
          const parsedData = JSON.parse(cachedData);
          
          setTimeout(() => {
            setAllContacts(parsedData);
            setFilteredContacts(parsedData);
            setTotalPages(Math.ceil(parsedData.length / CONTACTS_PER_PAGE));
            setLoading(false);
          }, 300); // Small delay to show the loading progress
          
          return;
        }
        
        // No valid cache, fetch from API
        setUsingCache(false);
        setLoadingProgress(0);
        
        // First make a request to get total count
        const initialResponse = await axios.get(
          'https://api.agristaff.doa.gov.lk/api/contacts.php?token=6DHanushKABaNdARa802&page=1&per_page=1'
        );
        
        if (initialResponse.data.status !== 'success') {
          throw new Error('Failed to fetch contacts information');
        }
        
        const totalContacts = initialResponse.data.meta.total;
        const totalPagesToFetch = Math.ceil(totalContacts / 100); // Fetch 100 per request
        setTotalToFetch(totalPagesToFetch);
        
        let allData = [];
        
        // Fetch all pages
        for (let page = 1; page <= totalPagesToFetch; page++) {
          const response = await axios.get(
            `https://api.agristaff.doa.gov.lk/api/contacts.php?token=6DHanushKABaNdARa802&page=${page}&per_page=100`
          );
          
          if (response.data.status === 'success') {
            allData = [...allData, ...response.data.data];
            // Update progress
            setLoadingProgress(Math.floor((page / totalPagesToFetch) * 100));
          } else {
            throw new Error(`Failed to fetch contacts page ${page}`);
          }
        }
        
        // Store data in localStorage for future use
        try {
          localStorage.setItem(CACHE_KEY, JSON.stringify(allData));
          localStorage.setItem(CACHE_TIMESTAMP_KEY, currentTime.toString());
        } catch (cacheError) {
          console.warn('Failed to cache contacts data:', cacheError);
          // Continue without caching
        }
        
        setAllContacts(allData);
        setFilteredContacts(allData);
        setTotalPages(Math.ceil(allData.length / CONTACTS_PER_PAGE));
      } catch (err) {
        setError('Error fetching contacts. Please try again later.');
        console.error('Error fetching contacts:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchAllContacts();
  }, []); // Only fetch once when component mounts

  // Update displayed contacts based on pagination
  useEffect(() => {
    const startIndex = (currentPage - 1) * CONTACTS_PER_PAGE;
    const endIndex = startIndex + CONTACTS_PER_PAGE;
    setDisplayedContacts(filteredContacts.slice(startIndex, endIndex));
  }, [currentPage, filteredContacts]);

  // Filter contacts whenever search terms change
  useEffect(() => {
    // Filter all contacts based on search terms
    const nameTermLower = searchTerm.toLowerCase().trim();
    const instituteTermLower = instituteSearchTerm.toLowerCase().trim();
    const stationTermLower = stationSearchTerm.toLowerCase().trim();
    const unitTermLower = unitSearchTerm.toLowerCase().trim();
    
    const filtered = allContacts.filter(contact => {
      const fullName = contact.fullName?.toLowerCase() || '';
      const instituteName = contact.institute?.name?.toLowerCase() || '';
      const stationName = contact.regional_station?.name?.toLowerCase() || '';
      const unitName = contact.unit?.name?.toLowerCase() || '';
      
      // Check if all search terms match
      const nameMatches = nameTermLower === '' || fullName.includes(nameTermLower);
      const instituteMatches = instituteTermLower === '' || instituteName.includes(instituteTermLower);
      const stationMatches = stationTermLower === '' || stationName.includes(stationTermLower);
      const unitMatches = unitTermLower === '' || unitName.includes(unitTermLower);
      
      return nameMatches && instituteMatches && stationMatches && unitMatches;
    });
    
    setFilteredContacts(filtered);
    setTotalPages(Math.ceil(filtered.length / CONTACTS_PER_PAGE));
    setCurrentPage(1); // Reset to first page after filtering
  }, [searchTerm, instituteSearchTerm, stationSearchTerm, unitSearchTerm, allContacts]);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
      window.scrollTo(0, 0);
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };
  
  const handleInstituteSearch = (e) => {
    setInstituteSearchTerm(e.target.value);
  };
  
  const handleStationSearch = (e) => {
    setStationSearchTerm(e.target.value);
  };
  
  const handleUnitSearch = (e) => {
    setUnitSearchTerm(e.target.value);
  };

  const clearSearch = () => {
    setSearchTerm('');
  };
  
  const clearInstituteSearch = () => {
    setInstituteSearchTerm('');
  };
  
  const clearStationSearch = () => {
    setStationSearchTerm('');
  };
  
  const clearUnitSearch = () => {
    setUnitSearchTerm('');
  };

  const clearAllSearches = () => {
    setSearchTerm('');
    setInstituteSearchTerm('');
    setStationSearchTerm('');
    setUnitSearchTerm('');
  };

  if (loading) return (
    <div className="loading">
      <div className="loading-container">
        <FaSpinner className="spinner-icon" />
        <div className="loading-text">
          {usingCache ? (
            <p>Loading contacts from cache...</p>
          ) : (
            <>
              <p>Loading all contacts data...</p>
              <div className="progress-container">
                <div 
                  className="progress-bar" 
                  style={{ width: `${loadingProgress}%` }}
                ></div>
              </div>
              <p className="progress-text">{loadingProgress}% Complete</p>
              <p className="loading-details">Fetched {Math.ceil((loadingProgress/100) * totalToFetch)} of {totalToFetch} pages</p>
            </>
          )}
        </div>
      </div>
    </div>
  );
  
  if (error) return <div className="error">{error}</div>;

  // Check if any search field has input
  const hasAnySearchInput = searchTerm || instituteSearchTerm || stationSearchTerm || unitSearchTerm;

  return (
    <div className="contacts-directory">
      <div className="directory-header">
        <div className="header-icon"><FaUsers /></div>
        <h2>Department of Agriculture Staff Directory</h2>
      </div>
      
      <div className="search-container">
        <div className="search-header">
          {hasAnySearchInput && (
            <button 
              className="clear-all-button" 
              onClick={clearAllSearches}
              aria-label="Clear all search fields"
            >
              <FaTimes className="clear-all-icon" /> Clear All
            </button>
          )}
        </div>
        <div className="search-row">
          <div className="search-box">
            <div className="input-wrapper">
              <FaSearch className="search-icon" />
              <input 
                type="text" 
                placeholder="Search by name..." 
                className="search-input"
                value={searchTerm}
                onChange={handleSearch}
                aria-label="Search by name"
              />
              {searchTerm && (
                <button 
                  className="clear-button" 
                  onClick={clearSearch}
                  aria-label="Clear name search"
                >
                  <FaTimes />
                </button>
              )}
            </div>
            <label className="search-label">Name</label>
          </div>
          
          <div className="search-box">
            <div className="input-wrapper">
              <FaBuilding className="search-icon" />
              <input 
                type="text" 
                placeholder="Search by institute..." 
                className="search-input"
                value={instituteSearchTerm}
                onChange={handleInstituteSearch}
                aria-label="Search by institute"
              />
              {instituteSearchTerm && (
                <button 
                  className="clear-button" 
                  onClick={clearInstituteSearch}
                  aria-label="Clear institute search"
                >
                  <FaTimes />
                </button>
              )}
            </div>
            <label className="search-label">Institute</label>
          </div>
        </div>
        <div className="search-row">
          <div className="search-box">
            <div className="input-wrapper">
              <FaMapMarkerAlt className="search-icon" />
              <input 
                type="text" 
                placeholder="Search by regional station..." 
                className="search-input"
                value={stationSearchTerm}
                onChange={handleStationSearch}
                aria-label="Search by regional station"
              />
              {stationSearchTerm && (
                <button 
                  className="clear-button" 
                  onClick={clearStationSearch}
                  aria-label="Clear regional station search"
                >
                  <FaTimes />
                </button>
              )}
            </div>
            <label className="search-label">Regional Station</label>
          </div>
          
          <div className="search-box">
            <div className="input-wrapper">
              <FaSitemap className="search-icon" />
              <input 
                type="text" 
                placeholder="Search by unit..." 
                className="search-input"
                value={unitSearchTerm}
                onChange={handleUnitSearch}
                aria-label="Search by unit"
              />
              {unitSearchTerm && (
                <button 
                  className="clear-button" 
                  onClick={clearUnitSearch}
                  aria-label="Clear unit search"
                >
                  <FaTimes />
                </button>
              )}
            </div>
            <label className="search-label">Unit</label>
          </div>
        </div>
      </div>
      
      {filteredContacts.length === 0 ? (
        <div className="no-results">No contacts found matching your search criteria</div>
      ) : (
        <div className="contacts-grid">
          {displayedContacts.map((contact, index) => (
            <div key={contact.id} className="card-container" style={{animationDelay: `${index * 0.05}s`}}>
              <ContactCard contact={contact} />
            </div>
          ))}
        </div>
      )}
      
      <div className="pagination">
        <button 
          className="pagination-button"
          disabled={currentPage === 1} 
          onClick={() => handlePageChange(currentPage - 1)}
        >
          <FaChevronLeft className="pagination-icon" /> Previous
        </button>
        <span className="page-indicator">Page {currentPage} of {totalPages}</span>
        <button 
          className="pagination-button"
          disabled={currentPage === totalPages} 
          onClick={() => handlePageChange(currentPage + 1)}
        >
          Next <FaChevronRight className="pagination-icon" />
        </button>
      </div>

      {/* Make this available to the global window object for use in the footer */}
      <div style={{ display: 'none' }} id="contact-stats" 
        data-total={allContacts.length} 
        data-filtered={filteredContacts.length}>
      </div>
    </div>
  );
};

export default ContactsDirectory; 