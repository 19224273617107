import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import './ContactDetail.css';
import { 
  FaUser, 
  FaBuilding, 
  FaArrowLeft, 
  FaPhoneAlt, 
  FaEnvelope, 
  FaMobile, 
  FaWhatsapp, 
  FaFax, 
  FaIdCard, 
  FaInfoCircle,
  FaMapMarkerAlt,
  FaSpinner,
  FaShareAlt,
  FaCopy,
  FaCheck,
  FaEnvelopeOpen
} from 'react-icons/fa';
import { MdWork, MdLocationCity } from 'react-icons/md';

const ContactDetail = () => {
  const { id } = useParams();
  const [contact, setContact] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [showShareOptions, setShowShareOptions] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);

  useEffect(() => {
    const fetchContactDetail = async () => {
      try {
        setLoading(true);
        // Try to use cached data first if available
        const cachedData = localStorage.getItem('agristaff_contacts_data');
        
        if (cachedData) {
          const parsedData = JSON.parse(cachedData);
          const foundContact = parsedData.find(c => c.id === parseInt(id));
          
          if (foundContact) {
            setContact(foundContact);
            setTimeout(() => {
              setIsVisible(true);
            }, 100);
            setLoading(false);
            return;
          }
        }
        
        // If no cached data or contact not found in cache, fetch from API
        const response = await axios.get(
          `https://api.agristaff.doa.gov.lk/api/contacts.php?token=6DHanushKABaNdARa802&page=1&per_page=100`
        );
        
        if (response.data.status === 'success') {
          const foundContact = response.data.data.find(c => c.id === parseInt(id));
          
          if (foundContact) {
            setContact(foundContact);
            setTimeout(() => {
              setIsVisible(true);
            }, 100);
          } else {
            throw new Error('Contact not found');
          }
        } else {
          throw new Error('Failed to fetch contact details');
        }
      } catch (err) {
        setError('Error fetching contact details. Please try again later.');
        console.error('Error fetching contact details:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchContactDetail();
  }, [id]);

  // Close share options when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showShareOptions && !event.target.closest('.share-options-container') && 
          !event.target.closest('.share-button')) {
        setShowShareOptions(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showShareOptions]);

  // Reset copy success message after 3 seconds
  useEffect(() => {
    let timeout;
    if (copySuccess) {
      timeout = setTimeout(() => {
        setCopySuccess(false);
      }, 3000);
    }
    return () => clearTimeout(timeout);
  }, [copySuccess]);

  // Format phone number to +94 xx xx xx xxx
  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return '';
    
    // Remove all non-digit characters
    let cleaned = phoneNumber.replace(/\D/g, '');
    
    // Check if already has country code
    if (!cleaned.startsWith('94')) {
      // If starts with 0, remove it before adding country code
      if (cleaned.startsWith('0')) {
        cleaned = cleaned.substring(1);
      }
      // Add country code
      cleaned = '94' + cleaned;
    } else if (cleaned.startsWith('94') && cleaned.length > 2 && cleaned.charAt(2) === '0') {
      // If it's like 940xxx, remove the 0 after 94
      cleaned = '94' + cleaned.substring(3);
    }
    
    // Format with spaces as +94 xx xx xx xxx
    if (cleaned.length === 11) { // 94 + 9 digits
      return `+${cleaned.substring(0, 2)} ${cleaned.substring(2, 4)} ${cleaned.substring(4, 6)} ${cleaned.substring(6, 8)} ${cleaned.substring(8)}`;
    } else {
      // If not the expected length, just add country code prefix
      return `+${cleaned.substring(0, 2)} ${cleaned.substring(2)}`;
    }
  };

  if (loading) return (
    <div className="loading detail-loading">
      <FaSpinner className="spinner-icon" />
      <p>Loading contact details...</p>
    </div>
  );
  
  if (error) return <div className="error">{error}</div>;
  if (!contact) return <div className="not-found">Contact not found</div>;

  const { 
    fullName, 
    title, 
    designation, 
    department, 
    institute, 
    regional_station,
    regional_sub_station,
    unit,
    contact_type,
    contact_status,
    contact_info,
    address,
    description
  } = contact;

  const isPerson = contact_type?.name === 'Person';

  // Format contact information for sharing
  const formatContactForSharing = () => {
    let formattedContact = `${fullName}\n`;
    
    if (designation?.name) formattedContact += `${designation.name}\n`;
    
    formattedContact += `\nOrganization Information:\n`;
    if (department?.name) formattedContact += `Department: ${department.name}\n`;
    if (institute?.name) formattedContact += `Institute: ${institute.name}\n`;
    if (regional_station?.name) formattedContact += `Regional Station: ${regional_station.name}\n`;
    if (regional_sub_station) formattedContact += `Regional Sub-Station: ${regional_sub_station}\n`;
    if (unit?.name) formattedContact += `Unit: ${unit.name}\n`;
    
    formattedContact += `\nContact Information:\n`;
    if (contact_info?.mobile?.primary) 
      formattedContact += `Mobile: ${formatPhoneNumber(contact_info.mobile.primary)}\n`;
    if (contact_info?.mobile?.secondary) 
      formattedContact += `Mobile (Secondary): ${formatPhoneNumber(contact_info.mobile.secondary)}\n`;
    if (contact_info?.mobile?.whatsapp) 
      formattedContact += `WhatsApp: ${formatPhoneNumber(contact_info.mobile.whatsapp)}\n`;
    if (contact_info?.office?.primary) 
      formattedContact += `Office: ${formatPhoneNumber(contact_info.office.primary)}\n`;
    if (contact_info?.office?.secondary) 
      formattedContact += `Office (Secondary): ${formatPhoneNumber(contact_info.office.secondary)}\n`;
    if (contact_info?.fax?.primary) 
      formattedContact += `Fax: ${formatPhoneNumber(contact_info.fax.primary)}\n`;
    if (contact_info?.fax?.secondary) 
      formattedContact += `Fax (Secondary): ${formatPhoneNumber(contact_info.fax.secondary)}\n`;
    if (contact_info?.email?.official) 
      formattedContact += `Email (Official): ${contact_info.email.official}\n`;
    if (contact_info?.email?.personal) 
      formattedContact += `Email (Personal): ${contact_info.email.personal}\n`;
    
    if (address) formattedContact += `\nAddress:\n${address}\n`;
    if (description) formattedContact += `\nAdditional Information:\n${description}\n`;
    
    return formattedContact;
  };

  // Copy contact details to clipboard
  const copyToClipboard = () => {
    const formattedContact = formatContactForSharing();
    navigator.clipboard.writeText(formattedContact)
      .then(() => {
        setCopySuccess(true);
        setShowShareOptions(false);
      })
      .catch(err => {
        console.error('Failed to copy contact details:', err);
      });
  };

  // Share via email
  const shareViaEmail = () => {
    const formattedContact = formatContactForSharing();
    const subject = `Contact Information: ${fullName}`;
    const mailtoLink = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(formattedContact)}`;
    window.location.href = mailtoLink;
    setShowShareOptions(false);
  };

  return (
    <div className={`contact-detail ${isVisible ? 'visible' : ''}`}>
      <div className="contact-header">
        <Link to="/" className="back-button">
          <FaArrowLeft className="back-icon" /> Back to Directory
        </Link>
        <div className="contact-actions">
          {copySuccess && <div className="copy-success"><FaCheck /> Copied!</div>}
          <div className="share-container">
            <button 
              className="share-button" 
              onClick={() => setShowShareOptions(!showShareOptions)}
              aria-label="Share contact"
            >
              <FaShareAlt className="share-icon" /> Share
            </button>
            
            {showShareOptions && (
              <div className="share-options-container">
                <div className="share-options">
                  <button 
                    className="share-option" 
                    onClick={copyToClipboard}
                  >
                    <FaCopy className="option-icon" /> Copy to clipboard
                  </button>
                  <button 
                    className="share-option" 
                    onClick={shareViaEmail}
                  >
                    <FaEnvelopeOpen className="option-icon" /> Share via email
                  </button>
                </div>
              </div>
            )}
          </div>
          <div className={`contact-type ${isPerson ? 'person' : 'building'}`}>
            {isPerson ? <FaUser className="type-icon" /> : <FaBuilding className="type-icon" />}
            {contact_type?.name}
          </div>
        </div>
      </div>
      
      <div className="contact-main-info">
        <div className="main-info-icon">
          {isPerson ? <FaUser className="avatar-icon" /> : <FaBuilding className="avatar-icon" />}
        </div>
        <div className="main-info-text">
          <h1 className="contact-name">
            {title?.name && `${title.name} `}{fullName}
          </h1>
          
          {designation?.name && (
            <div className="contact-position">
              <MdWork className="position-icon" />
              {designation.name}
            </div>
          )}
        </div>
      </div>

      <div className="contact-sections-container">
        <div className="contact-section organization-section animate-section">
          <h2><FaBuilding className="section-icon" /> Organization Information</h2>
          <div className="contact-info-grid">
            {department?.name && (
              <div className="info-item">
                <span className="info-label"><FaBuilding className="label-icon" /> Department:</span>
                <span className="info-value">{department.name}</span>
              </div>
            )}
            
            {institute?.name && (
              <div className="info-item">
                <span className="info-label"><MdLocationCity className="label-icon" /> Institute:</span>
                <span className="info-value">{institute.name}</span>
              </div>
            )}
            
            {regional_station?.name && (
              <div className="info-item">
                <span className="info-label"><FaMapMarkerAlt className="label-icon" /> Regional Station:</span>
                <span className="info-value">{regional_station.name}</span>
              </div>
            )}
            
            {regional_sub_station && (
              <div className="info-item">
                <span className="info-label"><FaMapMarkerAlt className="label-icon" /> Regional Sub-Station:</span>
                <span className="info-value">{regional_sub_station}</span>
              </div>
            )}
            
            {unit?.name && (
              <div className="info-item">
                <span className="info-label"><FaIdCard className="label-icon" /> Unit:</span>
                <span className="info-value">{unit.name}</span>
              </div>
            )}
            
            {contact_status?.name && (
              <div className="info-item">
                <span className="info-label"><FaInfoCircle className="label-icon" /> Status:</span>
                <span className="info-value status-badge">{contact_status.name}</span>
              </div>
            )}
          </div>
        </div>

        <div className="contact-section contact-info-section animate-section" style={{animationDelay: '0.2s'}}>
          <h2><FaPhoneAlt className="section-icon" /> Contact Information</h2>
          <div className="contact-info-grid">
            {/* Mobile phone(s) */}
            {contact_info?.mobile?.primary && (
              <div className="info-item">
                <span className="info-label"><FaMobile className="label-icon" /> Mobile:</span>
                <span className="info-value">
                  <a href={`tel:${contact_info.mobile.primary}`}>
                    {formatPhoneNumber(contact_info.mobile.primary)}
                  </a>
                </span>
              </div>
            )}
            
            {contact_info?.mobile?.secondary && (
              <div className="info-item">
                <span className="info-label"><FaMobile className="label-icon" /> Mobile (Secondary):</span>
                <span className="info-value">
                  <a href={`tel:${contact_info.mobile.secondary}`}>
                    {formatPhoneNumber(contact_info.mobile.secondary)}
                  </a>
                </span>
              </div>
            )}
            
            {contact_info?.mobile?.whatsapp && (
              <div className="info-item whatsapp-item">
                <span className="info-label"><FaWhatsapp className="label-icon whatsapp-icon" /> WhatsApp:</span>
                <span className="info-value">
                  {formatPhoneNumber(contact_info.mobile.whatsapp)}
                </span>
              </div>
            )}
            
            {/* Office phone(s) */}
            {contact_info?.office?.primary && (
              <div className="info-item">
                <span className="info-label"><FaPhoneAlt className="label-icon" /> Office:</span>
                <span className="info-value">
                  <a href={`tel:${contact_info.office.primary}`}>
                    {formatPhoneNumber(contact_info.office.primary)}
                  </a>
                </span>
              </div>
            )}
            
            {contact_info?.office?.secondary && (
              <div className="info-item">
                <span className="info-label"><FaPhoneAlt className="label-icon" /> Office (Secondary):</span>
                <span className="info-value">
                  <a href={`tel:${contact_info.office.secondary}`}>
                    {formatPhoneNumber(contact_info.office.secondary)}
                  </a>
                </span>
              </div>
            )}
            
            {/* Fax */}
            {contact_info?.fax?.primary && (
              <div className="info-item">
                <span className="info-label"><FaFax className="label-icon" /> Fax:</span>
                <span className="info-value">{formatPhoneNumber(contact_info.fax.primary)}</span>
              </div>
            )}
            
            {contact_info?.fax?.secondary && (
              <div className="info-item">
                <span className="info-label"><FaFax className="label-icon" /> Fax (Secondary):</span>
                <span className="info-value">{formatPhoneNumber(contact_info.fax.secondary)}</span>
              </div>
            )}
            
            {/* Email addresses */}
            {contact_info?.email?.official && (
              <div className="info-item email-item">
                <span className="info-label"><FaEnvelope className="label-icon email-icon" /> Email (Official):</span>
                <span className="info-value">
                  <a href={`mailto:${contact_info.email.official}`}>
                    {contact_info.email.official}
                  </a>
                </span>
              </div>
            )}
            
            {contact_info?.email?.personal && (
              <div className="info-item email-item">
                <span className="info-label"><FaEnvelope className="label-icon email-icon" /> Email (Personal):</span>
                <span className="info-value">
                  <a href={`mailto:${contact_info.email.personal}`}>
                    {contact_info.email.personal}
                  </a>
                </span>
              </div>
            )}
          </div>
        </div>
        
        {address && (
          <div className="contact-section address-section animate-section" style={{animationDelay: '0.4s'}}>
            <h2><FaMapMarkerAlt className="section-icon" /> Address</h2>
            <p className="contact-address">{address}</p>
          </div>
        )}
        
        {description && (
          <div className="contact-section description-section animate-section" style={{animationDelay: '0.6s'}}>
            <h2><FaInfoCircle className="section-icon" /> Additional Information</h2>
            <p className="contact-description">{description}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ContactDetail; 