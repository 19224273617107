import React from 'react';
import { Link } from 'react-router-dom';
import './ContactCard.css';
import { FaUser, FaBuilding, FaBriefcase, FaArrowRight } from 'react-icons/fa';
import { MdLocationOn } from 'react-icons/md';

const ContactCard = ({ contact }) => {
  const {
    id,
    fullName,
    designation,
    department,
    institute,
    contact_type,
  } = contact;

  // Determine card type - person or building
  const isPerson = contact_type?.name === 'Person';

  return (
    <Link to={`/contact/${id}`} className="card-link">
      <div className={`contact-card ${isPerson ? 'person' : 'building'} animate-card`}>
        <div className="card-header">
          <div className="card-icon">
            {isPerson ? <FaUser className="person-icon" /> : <FaBuilding className="building-icon" />}
          </div>
          <h3 className="card-name">{fullName}</h3>
          <div className="card-badge">{contact_type?.name}</div>
        </div>
        
        <div className="card-content">
          <div className="card-designation">
            {designation?.name ? (
              <>
                <FaBriefcase className="icon-small" />
                <span>{designation.name}</span>
              </>
            ) : null}
          </div>
          
          <div className="card-department">
            {department?.name ? (
              <>
                <MdLocationOn className="icon-small" />
                <span>{department.name}</span>
              </>
            ) : null}
          </div>
          
          <div className="card-institute">
            {institute?.name ? (
              <>
                <FaBuilding className="icon-small" />
                <span>{institute.name}</span>
              </>
            ) : null}
          </div>
        </div>
        
        <div className="view-details">
          View Details <FaArrowRight className="arrow-icon" />
        </div>
      </div>
    </Link>
  );
};

export default ContactCard; 