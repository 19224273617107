import React from 'react';
import { Link } from 'react-router-dom';
import { FaArrowLeft, FaInfoCircle, FaBullseye, FaBuilding, FaTag } from 'react-icons/fa';
import SEO from './SEO';
import './AboutUs.css';

const AboutUs = () => {
  return (
    <div className="about-page">
      <SEO 
        title="About Us - AgriStaff Directory | Department of Agriculture Sri Lanka"
        description="Learn about the AgriStaff Directory, the official contact management system for the Department of Agriculture, Sri Lanka. Our mission and information about our department."
        pathname="/about"
      />
      <div className="about-header">
        <div className="header-overlay">
          <div className="header-content">
            <FaInfoCircle className="about-icon" />
            <h1>About Us</h1>
          </div>
        </div>
      </div>
      
      <div className="about-container">
        <div className="back-nav">
          <Link to="/" className="back-button">
            <FaArrowLeft className="back-icon" /> Back to Directory
          </Link>
        </div>
        
        <div className="about-content">
          <p className="intro-text">
            The Agri Staff Contact Directory is the official contact management system for the Department of Agriculture, Sri Lanka.
          </p>
          
          <div className="about-section">
            <div className="section-header">
              <FaBullseye className="section-icon" />
              <h2>Our Mission</h2>
            </div>
            <div className="section-content">
              <p>
                To provide a comprehensive and up-to-date contact directory for all staff members, departments, institutes, and regional stations under the Department of Agriculture.
              </p>
            </div>
          </div>
          
          <div className="about-section">
            <div className="section-header">
              <FaBuilding className="section-icon" />
              <h2>About Department of Agriculture</h2>
            </div>
            <div className="section-content">
              <p>
                The Department of Agriculture (DOA) functions under the Ministry of Agriculture and is one of the largest government departments with a high disciplined work force of over 5000. The DOA is one of the main institutions coming under the purview of the Ministry of Agriculture.
              </p>
              <div className="department-image-container">
                <img 
                  src="/images/doa-headquarters.jpg" 
                  alt="Department of Agriculture Headquarters" 
                  className="department-image"
                  onError={(e) => {
                    e.target.style.display = 'none';
                  }}
                />
              </div>
            </div>
          </div>
          
          <div className="version-info">
            <FaTag className="version-icon" />
            <span>Contact Directory Version: 10.0.0</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs; 