import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaArrowLeft, FaShieldAlt, FaMobileAlt, FaLock, FaUserShield, FaHandshake, FaBullhorn, FaPhoneAlt, FaCloudDownloadAlt } from 'react-icons/fa';
import SEO from './SEO';
import './PrivacyPolicy.css'; // Reusing the same CSS

const MobileAppPrivacyPolicy = () => {
  const [activeSection, setActiveSection] = useState(null);

  const toggleSection = (sectionId) => {
    if (activeSection === sectionId) {
      setActiveSection(null);
    } else {
      setActiveSection(sectionId);
      // Scroll to section
      const element = document.getElementById(`mobile-section-${sectionId}`);
      if (element) {
        const headerOffset = 100;
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
        
        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });
      }
    }
  };
  
  return (
    <div className="privacy-policy-page">
      <SEO 
        title="Mobile App Privacy Policy - AgriStaff Directory | Department of Agriculture Sri Lanka"
        description="Privacy policy for the AgriStaff Directory mobile application. Learn about data collection, permissions, security measures, and how we protect your information when using our mobile app."
        pathname="/mobile-privacy-policy"
      />
      <div className="privacy-header">
        <div className="header-overlay">
          <div className="header-content">
            <FaMobileAlt className="privacy-shield-icon" />
            <h1>Mobile App Privacy Policy</h1>
            <p className="effective-date">Effective Date: January 1, 2025</p>
          </div>
        </div>
      </div>
      
      <div className="privacy-container">
        <div className="back-nav">
          <Link to="/" className="back-button">
            <FaArrowLeft className="back-icon" /> Back to Directory
          </Link>
        </div>
        
        <div className="policy-layout">
          <nav className="policy-nav">
            <ul>
              <li className={activeSection === 1 ? 'active' : ''}>
                <button onClick={() => toggleSection(1)}>
                  <FaCloudDownloadAlt className="nav-icon" /> App Information
                </button>
              </li>
              <li className={activeSection === 2 ? 'active' : ''}>
                <button onClick={() => toggleSection(2)}>
                  <FaLock className="nav-icon" /> Information Collection
                </button>
              </li>
              <li className={activeSection === 3 ? 'active' : ''}>
                <button onClick={() => toggleSection(3)}>
                  <FaHandshake className="nav-icon" /> Purpose of Information
                </button>
              </li>
              <li className={activeSection === 4 ? 'active' : ''}>
                <button onClick={() => toggleSection(4)}>
                  <FaShieldAlt className="nav-icon" /> Data Security
                </button>
              </li>
              <li className={activeSection === 5 ? 'active' : ''}>
                <button onClick={() => toggleSection(5)}>
                  <FaUserShield className="nav-icon" /> Device Permissions
                </button>
              </li>
              <li className={activeSection === 6 ? 'active' : ''}>
                <button onClick={() => toggleSection(6)}>
                  <FaBullhorn className="nav-icon" /> Policy Updates
                </button>
              </li>
              <li className={activeSection === 7 ? 'active' : ''}>
                <button onClick={() => toggleSection(7)}>
                  <FaPhoneAlt className="nav-icon" /> Contact Us
                </button>
              </li>
            </ul>
          </nav>
          
          <div className="privacy-content">
            <p className="intro-text">
              Welcome to the Agri Staff Contact Directory mobile application. This Privacy Policy outlines how our mobile app collects, uses, and protects the data when you use the application.
            </p>
            
            <div id="mobile-section-1" className="policy-section">
              <div className="section-header">
                <FaCloudDownloadAlt className="section-icon" />
                <h2>1. Mobile Application Information</h2>
              </div>
              <div className="section-content">
                <p>
                  The AgriStaff Directory mobile application is an official app of the Department of Agriculture, Sri Lanka, designed to provide easy access to contact information of agriculture staff and officials. The app is available on Android and iOS platforms.
                </p>
              </div>
            </div>
            
            <div id="mobile-section-2" className="policy-section">
              <div className="section-header">
                <FaLock className="section-icon" />
                <h2>2. Information Collection and Usage</h2>
              </div>
              <div className="section-content">
                <p>
                  Our mobile application collects the following information:
                </p>
                <ul>
                  <li><strong>Usage Data:</strong> Anonymous information about how you use the app, including screens viewed and features used.</li>
                  <li><strong>Device Information:</strong> Basic details about your mobile device, such as model, operating system, and unique device identifiers for analytics purposes.</li>
                  <li><strong>Search History:</strong> We may save your recent searches within the app to improve your user experience.</li>
                </ul>
              </div>
            </div>
            
            <div id="mobile-section-3" className="policy-section">
              <div className="section-header">
                <FaHandshake className="section-icon" />
                <h2>3. Purpose of Information</h2>
              </div>
              <div className="section-content">
                <p>
                  The information collected is used for:
                </p>
                <ul>
                  <li>Improving the app's functionality and user experience</li>
                  <li>Troubleshooting technical issues</li>
                  <li>Analytics to understand user behavior and optimize performance</li>
                  <li>Providing personalized search results and contact suggestions</li>
                </ul>
              </div>
            </div>
            
            <div id="mobile-section-4" className="policy-section">
              <div className="section-header">
                <FaShieldAlt className="section-icon" />
                <h2>4. Data Security</h2>
              </div>
              <div className="section-content">
                <p>
                  We implement appropriate security measures to protect your data from unauthorized access, alteration, or disclosure. Data is stored locally on your device and synchronized with our secure servers when an internet connection is available.
                </p>
              </div>
            </div>
            
            <div id="mobile-section-5" className="policy-section">
              <div className="section-header">
                <FaUserShield className="section-icon" />
                <h2>5. Device Permissions</h2>
              </div>
              <div className="section-content">
                <p>
                  Our app may request the following permissions:
                </p>
                <ul>
                  <li><strong>Phone:</strong> To allow direct calling to contacts from the app</li>
                  <li><strong>Storage:</strong> To save and access contact information locally</li>
                  <li><strong>Internet:</strong> To fetch and update contact information</li>
                  <li><strong>Camera:</strong> For QR code scanning of contacts (optional)</li>
                </ul>
                <p>
                  You can manage these permissions through your device settings at any time.
                </p>
              </div>
            </div>
            
            <div id="mobile-section-6" className="policy-section">
              <div className="section-header">
                <FaBullhorn className="section-icon" />
                <h2>6. Updates to This Policy</h2>
              </div>
              <div className="section-content">
                <p>
                  We may update this Mobile App Privacy Policy periodically. When changes are made, we will notify you through the app and provide a link to review the updated policy before continuing to use the application.
                </p>
              </div>
            </div>
            
            <div id="mobile-section-7" className="policy-section">
              <div className="section-header">
                <FaPhoneAlt className="section-icon" />
                <h2>7. Contact Us</h2>
              </div>
              <div className="section-content">
                <p>
                  If you have questions or concerns about this Mobile App Privacy Policy, please contact us at:
                </p>
                <div className="contact-box">
                  <address>
                    <strong>Television & Farm Broadcasting Service</strong><br />
                    National Agriculture Information and Communication Center<br />
                    Peradeniya, 20400<br /><br />
                    <a href="tel:+94812388388">+94 81 2 388 388</a><br />
                    <a href="mailto:agristaff@doa.gov.lk">agristaff@doa.gov.lk</a>
                  </address>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <footer className="privacy-footer">
          <div className="footer-content">
            <div className="copyright-text">
              © 2016 - {new Date().getFullYear()} Department of Agriculture, Sri Lanka. All rights reserved.
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default MobileAppPrivacyPolicy; 